import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { MdWindPower } from "react-icons/md";
import { FaArrowsAltV } from "react-icons/fa";
import background from "./background.jpg";
import blackImage from "./blackBackground.jpg";
import { CardImgOverlay, Card, CardImg, CardTitle } from "reactstrap";
import { MdOutlineLocationOn,  MdOutlineElectricBolt,MdPeople, MdDateRange, MdAccessTime } from "react-icons/md";
import logo from "./logo.png";

const Header = () => {
  return (
    <div>
      <div>
        <Card inverse>
          <CardImg
            alt="Card image cap"
            src={background}
            style={{ maxHeight: "600px", objectFit: "cover" }}
            width="100%"
          />
          <CardImgOverlay>
            {/* 
       <img
          alt=""
          src={logo}
          width="100"
          height="70"
          style={{ position: 'relative', top: '50px',left:'40px' }} // Adjust the 'top' value as needed
        />
        */}
            <CardTitle tag="h5" className="title">
            Vindpark Östergötland 
            </CardTitle>
          </CardImgOverlay>
        </Card>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ maxHeight: "200px", objectFit: "cover", opacity: "0.9" }}
            src={blackImage}
            alt="First slide"
          />
          <Carousel.Caption
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div>
              <MdWindPower className="icon" />
              <h6 className="iconText">Antal Verk </h6>
              <p className="iconTitle">16</p>
            </div>
            <div>
              <FaArrowsAltV className="icon" />
              <h6 className="iconText">Totalhöjd </h6>
              <p className="iconTitle">270 meter</p>
            </div>

            <div>
              <MdOutlineLocationOn className="icon"  />
              <h6 className="iconText"> Kommun </h6>
              <p className="iconTitle">Valdemarsvik</p>
            </div>

            <div>
              <MdOutlineElectricBolt className="icon"  />
              <h6 className="iconText">Årlig Produktion</h6>
              <p className="iconTitle">385 GWh</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ maxHeight: "200px", objectFit: "cover" }}
            src={blackImage}
            alt="First slide"
          />
          <Carousel.Caption
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div>
              <MdPeople className="icon"  />
              <h6 className="iconText">Välkommen till</h6>
              <p className="iconTitle">Samråd / Öppet Hus</p>
              
            </div>
            <div>
              <MdOutlineLocationOn className="icon" />
              <h6 className="iconText">Plats </h6>
              <h6 className="iconText"> Folket Hus i Gusum <br></br> Valdemarsviks kommun</h6>
            </div>

            <div>
              <MdDateRange className="icon"  />
              <h6 className="iconText">Datum </h6>
              <p className="iconTitle">14 Nov 2023</p>
            </div>

            <div>
              <MdAccessTime className="icon"  />
              <h6 className="iconText">Tid </h6>
              <p className="iconTitle">15:30-18:30</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Header;
