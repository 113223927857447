import React from "react";
import {
  CardGroup,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  
} from "reactstrap";


const ContactPerson = () => {
  return (
    <div>
      <CardGroup>
        <Card>
         {/*<CardImg
            alt="Card image cap"
            src={person} 
            top
            width="100%"
            style={{ height: '350px', objectFit: 'cover' }}
          /> */} 
          <CardBody>
            <CardTitle tag="h5">Jacob Falkman</CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
            jacob@blas.se
            </CardSubtitle>
            <CardText>
            
            </CardText>
          </CardBody>
        </Card>
        
      </CardGroup>
    </div>
  );
};

export default ContactPerson;
