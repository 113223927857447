import React from 'react'

const Footer = () => {
    return (
        <footer style={footerStyle}>
        <p>Vindpark Östergötland AB, Trollsjövägen 167, 237 33 Bjärred. Tel: 0709-426615</p>
        <br></br>
        <p>Skicka e-post </p>
        <br></br>
        <p>jacob@blas.se</p>
        </footer>
      );
    }
    
    const footerStyle = {
      background: '#333',
      color: '#fff',
      textAlign: 'center',
      padding: '6% 20%', 
      marginTop: '60px',
     
    };

export default Footer
