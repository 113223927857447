import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import Header from "./Header";
import News from "./News";
import FactAndInfor from "./FactAndInfor";
import ContactPerson from "./ContactPerson";
import Footer from "./Footer";

function App() {
  return (
    <>
      <Header />

      <Container className="mt-5">
        <h3>Nyheter</h3>
        <h5 style={{ marginBottom: "30px" }}></h5>

        <News />
      </Container>

      <Container className="mt-5">
        <h3 style={{ marginBottom: "30px" }}>Fakta och information</h3>
        <FactAndInfor />
      </Container>

      <Container style={{ marginTop: "80px" }}>
        <h3>Kontaktpersoner</h3>
      </Container>

      <Container className="mt-5" >
        <ContactPerson />
      </Container>

      <Footer />
    </>
  );
}

export default App;
